import React from "react";
import {
    EditGuesser,
    InputGuesser,
} from "@api-platform/admin";

import { ReferenceInput, AutocompleteInput } from 'react-admin'

const SocialAccountEdit = props => (
    <EditGuesser {...props}>
        <ReferenceInput label="Shop" source="shop" reference="shops" perPage={15} filterToQuery={searchText => ({ name: searchText })} allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="User" source="user" reference="users" perPage={15} filterToQuery={searchText => ({ name: searchText })} allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source="account_name" />
    </EditGuesser>
);

export default SocialAccountEdit
