import React from "react";
import {
    EditGuesser,
    InputGuesser,
} from "@api-platform/admin";

import { ReferenceInput, AutocompleteInput } from 'react-admin'

const DiscountEdit = props => (
    <EditGuesser {...props}>
        <ReferenceInput label="Owner" source="owner" reference="users" perPage={15} filterToQuery={searchText => ({ name: searchText })} allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Shop" source="shop" reference="shops" perPage={15} filterToQuery={searchText => ({ name: searchText })}>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <InputGuesser label="Instagram Username" source="username" />
    </EditGuesser>
);

export default DiscountEdit
