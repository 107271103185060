import * as Categories from './Categories'
import * as CustomerIssues from './CustomerIssues';
import * as Discounts from './Discounts'
import * as Users from './Users'
import * as Shops from './Shops'
import * as Products from './Products';
import * as Notifications from './Notifications';
import * as Affiliates from './Affiliates';
import * as SocialAccounts from './SocialAccounts';

export default {
    Affiliates,
    Categories,
    CustomerIssues,
    Discounts,
    Notifications,
    Products,
    Shops,
    Users,
    SocialAccounts
}
