import React from "react";
import {
    ListGuesser,
    FieldGuesser
} from "@api-platform/admin";
import {
    TextField,
    ReferenceField
} from 'react-admin';

const SocialAccountsList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source="account_id" label="Account ID" />
        <FieldGuesser source="account_name" label="Account Name" />
        <ReferenceField source="shop" reference="shops" linkType={"show"} label={"Shop"} addLabel={true}>
            <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="user" reference="users" linkType={"show"} label={"User"} addLabel={true}>
            <TextField source="name"/>
        </ReferenceField>
    </ListGuesser>
);

export default SocialAccountsList;
